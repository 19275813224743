import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams
} from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import Home from './components/Home'
import Table from './components/Table'
import TableActions from './components/TableActions'
import TableDesign from './components/TableDesign'
import { useCountryData } from './components/queryHooks'

const TableId = () => {
  const { tableId } = useParams()
  const { isLoading, isFetching, data, isError, error } = useCountryData({ tableId })

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div>{data?.country}</div>
  )
}

const App = () => {

  return (
    <BrowserRouter>
      <div>
        <NavLink to="table-design">Home</NavLink> |{' '}
      </div>
      <Routes>
        <Route path="/table-design" element={<TableDesign />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
