import React, { useRef, useCallback } from 'react'
import styled from 'styled-components'
import { isObject } from 'lodash'
import { Formik, Form, useFormikContext, useField } from 'formik'
import { useTable, usePagination } from 'react-table'
import NumberFormat from 'react-number-format'

const FONT_SIZE = '15px'

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    font-size: ${FONT_SIZE};

    .hide-border-bottom {
      border-bottom: none;
      text-align: center;
    }

    .hide-border-right {
      border-right: none;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }

      input {
        font-size: ${FONT_SIZE};
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`

const StyledInput = styled.input``

const Input = (props) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)
  const { disabled, placeholder, initialValue, type, valuePrefix } = props
  const { value } = field

  const handleOnKeyDown = (e) => {
    if (e.key === 'Escape') {
      setFieldValue(field.name, initialValue)
    }
  }

  const defaultValues = {
    number: ""
  }

  if (type === 'number') {
    return (
      <NumberFormat
        prefix={valuePrefix}
        placeholder={placeholder}
        thousandSeparator
        value={isObject(value) ? value.formattedValue : value}
        onValueChange={val =>
          setFieldValue(field.name, val.floatValue || defaultValues[field.name])
        }
        onKeyDown={(e) => handleOnKeyDown(e)}
        disabled={disabled}
        {...field}
      />
    )
  }

  return (
    <StyledInput
      onKeyDown={(e) => handleOnKeyDown(e)}
      {...field}
      {...props}
    />
  )
}

// Editable Cell applied on all non-custom table cells and enabled on active
const EditableCell = ({
  value: initialValue,
  ...props
}) => {
  const {
    cell: { column: { component, componentType, componentPrefix, componentPlaceholder, id } },
    disabled
  } = props

  switch (component) {
    case 'input':
      return (
        <Input
          name={`data[${props.cell.row.index}].${[id]}`}
          type={componentType}
          placeholder={disabled ? componentPlaceholder : ''}
          disabled={false}
          initialValue={initialValue}
          valuePrefix={componentPrefix}
        />
      )
    default:
      return (
        <div>This custom component is not defined</div>
      )
  }
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
}

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, skipPageReset }) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      initialState: {
        pageSize: 80,
      },
      columns,
      data,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    usePagination
  )

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  const columnId = cell.column.id
                  const index = row.index + 1
                  const hideBorderBottom =
                    ([1, 2, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 18, 19, 20, 21, 24, 25].includes(index) && ["budget", "budget_subcategory"].includes(columnId)) || ([26].includes(index) && ["budget"].includes(columnId))
                      ? "hide-border-bottom" : 'show-border-bottom'
                  const hideBorderRight = [4, 17, 23, 28].includes(index) && ["budget", "budget_subcategory", "category_of_works"].includes(columnId) ? "hide-border-right" : 'show-border-right'
                  return <td className={hideBorderBottom + ' ' + hideBorderRight} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

function TableDesign() {
  const columns = React.useMemo(
    () => [
      {
        accessor: "budget",
        Header: "Budget",
        Cell: tableProps => {
          return <span>{tableProps.cell.value}</span>
        }
      },
      {
        accessor: "budget_subcategory",
        Header: "Budget Subcategory",
        Cell: tableProps => {
          return <span>{tableProps.cell.value}</span>
        }
      },
      {
        accessor: "category_of_works",
        Header: "Category of works",
        Cell: tableProps => {
          return <span>{tableProps.cell.value}</span>
        }
      },
      {
        accessor: "cost_type",
        Header: "Cost type",
        Cell: tableProps => {
          return <span>{tableProps.cell.value}</span>
        }
      },
      {
        accessor: "cost",
        Header: "Cost (EUR / Category of works)",
        component: 'input',
        componentType: 'number',
        componentPrefix: 'EUR ',
        componentPlaceholder: 'Click to edit this line'
      },
      {
        accessor: "total",
        Header: "% of total",
        component: 'input',
        componentType: 'number',
        componentPrefix: 'EUR ',
        componentPlaceholder: 'Click to edit this line'
      },
      {
        accessor: "cost_sqm",
        Header: "Cost (EUR /sqm)",
        component: 'input',
        componentType: 'number',
        componentPrefix: 'EUR ',
        componentPlaceholder: 'Click to edit this line'
      }
    ],
    []
  )

  const dataHard = React.useMemo(() => [
    {
      category_of_works: "Land aquisition",
      cost_type: "Land",
      cost: 875111,
      total: 63475.28111,
      cost_sqm: 1111
    },
    {
      budget: "Land",
      budget_subcategory: "Land",
      category_of_works: "Due diligence and / or other studies",
      cost_type: "Land",
      cost: 1004222,
      total: 76608.93222,
      cost_sqm: 2222
    },
    {
      category_of_works: "Site Preparation (demolition, deforastation etc.)",
      cost_type: "Land",
      cost: 591333,
      total: 43242.02333,
      cost_sqm: 3333
    },
    {
      cost_type: "Total Land",
      cost: 0,
      total: 0,
      cost_sqm: 0
    },
    {
      category_of_works: "Design all phases (DTAC, PTh, DDE), including project verification and technical assistance during execution",
      cost_type: "Soft",
      cost: 1004,
      total: 76608.93,
      cost_sqm: 2
    },
    {
      category_of_works: "Specialty studies and measurements (topo, geo, structural surveying etc)",
      cost_type: "Soft",
      cost: 1004,
      total: 76608.93,
      cost_sqm: 2
    },
    {
      category_of_works: "Consultancy & Project management",
      cost_type: "Soft",
      cost: 1004,
      total: 76608.93,
      cost_sqm: 2
    },
    {
      category_of_works: "Site supervision (Dirigentie de santier)",
      cost_type: "Soft",
      cost: 1004,
      total: 76608.93,
      cost_sqm: 2
    },
    {
      category_of_works: "Health & Safety coordination services",
      cost_type: "Soft",
      cost: 1004,
      total: 76608.93,
      cost_sqm: 2
    },
    {
      category_of_works: "Authorities taxes (permits, endorsements, works commencement, reception)",
      cost_type: "Soft",
      cost: 1004,
      total: 76608.93,
      cost_sqm: 2
    },
    {
      budget: "Soft Costs",
      budget_subcategory: "Soft Costs",
      category_of_works: "Sales & Marketing",
      cost_type: "Soft",
      cost: 1004,
      total: 76608.93,
      cost_sqm: 2
    },
    {
      category_of_works: "Insurance fees",
      cost_type: "Soft",
      cost: 1004,
      total: 76608.93,
      cost_sqm: 2
    },
    {
      category_of_works: "Legal fees",
      cost_type: "Soft",
      cost: 1004,
      total: 76608.93,
      cost_sqm: 2
    },
    {
      category_of_works: "Notary fees (land registry)",
      cost_type: "Soft",
      cost: 1004,
      total: 76608.93,
      cost_sqm: 2
    },
    {
      category_of_works: "Other taxes",
      cost_type: "Soft",
      cost: 1004,
      total: 76608.93,
      cost_sqm: 2
    },
    {
      category_of_works: "Financing fees (project monitoring etc)",
      cost_type: "Soft",
      cost: 1004,
      total: 76608.93,
      cost_sqm: 2
    },
    {
      cost_type: "Total Soft Costs",
      cost: 0,
      total: 0,
      cost_sqm: 0
    },
    {
      category_of_works: "Site security services",
      cost_type: "Site Organization",
      cost: 875,
      total: 63475.28,
      cost_sqm: 1
    },
    {
      category_of_works: "Containers and toilets",
      cost_type: "Site Organization",
      cost: 875,
      total: 63475.28,
      cost_sqm: 1
    },
    {
      budget: "Site organization",
      budget_subcategory: "Site organization",
      category_of_works: "Site enclosure and site panel",
      cost_type: "Site Organization",
      cost: 875,
      total: 63475.28,
      cost_sqm: 1
    },
    {
      category_of_works: "Crane and / or other equipments",
      cost_type: "Site Organization",
      cost: 875,
      total: 63475.28,
      cost_sqm: 1
    },
    {
      category_of_works: "Site utilities during construction",
      cost_type: "Site Organization",
      cost: 875,
      total: 63475.28,
      cost_sqm: 1
    },
    {
      cost_type: "Total site organization",
      cost: 0,
      total: 0,
      cost_sqm: 0
    },
    {
      category_of_works: "Dewatering works",
      cost_type: "Hard Cost",
      cost: 875,
      total: 63475.28,
      cost_sqm: 1
    },
    {
      budget: "Structural works",
      budget_subcategory: "Infrastructure",
      category_of_works: "Excavation works",
      cost_type: "Hard Cost",
      cost: 875,
      total: 63475.28,
      cost_sqm: 1
    },
    {
      category_of_works: "Reinforced concrete works",
      cost_type: "Hard Cost",
      cost: 875,
      total: 63475.28,
      cost_sqm: 1
    },
    {
      budget_subcategory: "Superstructure",
      category_of_works: "Reinforced concrete works",
      cost_type: "Hard Cost",
      cost: 875,
      total: 63475.28,
      cost_sqm: 1
    },
    {
      cost_type: "Total structural works",
      cost: 0,
      total: 0,
      cost_sqm: 0
    },

  ], [])

  const [skipPageReset, setSkipPageReset] = React.useState(false)

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false)
  }, [dataHard])

  //revert row changes
  const formikRef = useRef()
  const revertRowChanges = useCallback(() => {
    formikRef.current?.resetForm()
  }, [])

  return (
    <>
      <button tyoe="button" onClick={revertRowChanges}>Reset Data</button>
      <Formik
        id='custom-design-form'
        innerRef={formikRef}
        initialValues={{
          data: dataHard
        }}
        onSubmit={async (values) => {
          console.log(values)
        }}
      >
        <Form>
          <Styles>
            <Table
              columns={columns}
              data={dataHard}
              skipPageReset={skipPageReset}
            />
          </Styles>
          <button type="submit">Save values</button>
        </Form>
      </Formik>
    </>
  )
}

export default TableDesign
